#card {
    transition: 0.3s;
    height: auto;
    width: 100%;
    color: var(--verde-claro);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #151E15;
    border: solid 2px none;
    box-shadow: 15px 15px 12px -5px rgb(53, 133, 87, 0.5);
    -webkit-box-shadow: 15px 15px 12px -5px rgb(53, 133, 87, 0.5);
    border-radius: 10px;
    object-fit: cover;
    min-width: 100%;
    min-height: 250px;
}

#card svg {
    width: 50px;
    height: auto;
}

#card:hover {
    transition: 0.3s;
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 0px 14px 4px var(--verde-claro);
}

@media screen and (max-width: 968px) {
    #card {
        width: 80%;
        height: 80%;
        min-width: 80%;
        min-height: 150px;
    }
}