:root {
    --roxo: #8c398f;
    --verde-escuro: #04b46b;
    --verde-claro: #6BFFA9;
    --bg: #0b3f23;
    --red: rgb(180, 27, 27);
    --secondary-colour: #fafafa;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Calibri";
}

.container {
    width: 80%;
    min-height: 60vh;
    height: auto;
    overflow: hidden;
    padding: 25px 0;
}

.title {
    width: fit-content;
    min-width: none;
    max-width: none;
    transition: .5s;
    margin-bottom: 2rem;
    font-size: 35px;
    text-transform: capitalize;
    position: relative;
    font-weight: 700;
    font-variant-caps: all-petite-caps;
}

.title::before {
    transition: .5s;
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: var(--roxo);
    height: 3px;
    box-sizing: border-box;
    width: 30%;
    min-width: 70px;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #27573c;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb {
    background-color: #358557;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
label,
input {
    color: white;
    font-family: var(--font-family);
    font-weight: 700;
}

html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    color: white;
}