#sidebar {
    position: fixed;
    border-radius: 20px;
    right: 40px;
    height: auto;
    width: 60px;
    background-color: var(--verde-escuro);
    z-index: 99;
    top: 50%;
    transform: translate(50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;
    gap: 10px;
}

#sidebar svg {
    transition: .5s ease-in-out;
    height: 30px;
    width: auto;
    color: white;
    cursor: pointer;
    margin-top: 5px;
}

#sidebar div {
    position: relative;
}

#sidebar svg:hover {
    transition: .5s ease-in;
    color: var(--roxo);
    transform: scale(1.2);
}

#sidebar label {
    transition: .3s ease-out;
    position: absolute;
    top: 0;
    right: 60px;
    background-color: var(--verde-escuro);
    padding: 10px 20px;
    border-radius: 10px;
    transform: scale(0);
    text-align: center;
}

#sidebar .label-7 {
    min-width: 130px;
}

#sidebar a:hover~label {
    transition: .3s ease-in;
    transform: scale(1);
}

@media screen and (max-width: 968px) {
    #sidebar {
        right: unset;
        bottom: 5vh;
        top: unset;
        transform: translate(0, 0);
        flex-direction: row;
        height: 60px;
        width: max-content;
        padding: 0 20px;
        left: 0;
        max-width: 90%;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        gap: 20px;
    }
    #sidebar label {
        display: none;
    }
}