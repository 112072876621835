#raridade {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#raridade .container {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

#raridade .grid {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-bottom: 20px;
}

#raridade .grid div {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#raridade .grid div img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

#raridade .grid div:nth-of-type(3) {
    transform: scale(1.1);
}

#raridade .grid :is(div:nth-of-type(4), div:nth-of-type(2)) {
    transform: scale(1.05);
}

@media screen and (max-width: 968px) {
    #raridade .grid {
        flex-direction: column;
    }
    #raridade .grid div:nth-of-type(3) {
        transform: none;
    }
    #raridade .grid :is(div:nth-of-type(4), div:nth-of-type(2)) {
        transform: none;
    }
}