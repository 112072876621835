#modal {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(24, 24, 24, 0.95);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    --degrade-cor-1: rgba(154, 241, 190, 0.8);
    --degrade-cor-2: rgba(154, 241, 190, 0.2);
    --box-cor: rgb(25, 80, 49);
}

#modal .icon {
    transition: .5s;
    top: 10px;
    right: 40px;
    position: absolute;
    color: var(--verde-claro);
    cursor: pointer;
    width: 40px;
    height: 40px;
}

#modal .icon:hover {
    transition: .5s;
    color: var(--roxo);
}

#modal .popUp {
    transition: .5s;
    width: 70vw;
    height: 70vh;
}

#modal .popUp .container-1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

#modal .popUp .container-1 img {
    width: 35%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    transform: rotateZ(-3deg);
    animation: example backwards 5s infinite;
}

@keyframes example {
    0% {
        box-shadow: 20px 12px 12px 1px var(--degrade-cor-1);
        -webkit-box-shadow: 20px 12px 12px 1px var(--degrade-cor-1);
    }
    50% {
        box-shadow: 20px 12px 12px 1px var(--degrade-cor-2);
        -webkit-box-shadow: 20px 12px 12px 1px var(--degrade-cor-2);
    }
    100% {
        box-shadow: 20px 12px 12px 1px var(--degrade-cor-1);
        -webkit-box-shadow: 20px 12px 12px 1px var(--degrade-cor-1);
    }
}

#modal .popUp .container-1 .statistics {
    width: 55%;
    height: 100%;
    padding: 20px;
    padding-right: 50px;
    color: white;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 20px;
    column-gap: 30px;
    grid-auto-rows: minmax(100px, auto);
}

#modal .popUp .container-1 .chars {
    width: 100%;
    height: 100%;
    background-color: var(--box-cor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 27px 30px 12px -25px var(--degrade-cor-2);
    -webkit-box-shadow: 27px 30px 12px -25px var(--degrade-cor-2);
}

#modal .popUp .container-1 .chars:nth-of-type(1) {
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
}

#modal .popUp .container-1 .chars:nth-of-type(1) span {
    font-size: 60px;
}

#modal .popUp .container-1 .chars label {
    height: 10%;
    text-overflow: ellipsis;
}

#modal .popUp .container-1 .chars span {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 40px;
    color: var(--verde-claro);
    text-overflow: ellipsis;
}

#modal .popUp .container-1 .chars:nth-of-type(1) {
    grid-column: 1 / 3;
    grid-row: 1;
}

@media screen and (max-width: 968px) {
    #modal {
        height: auto;
        width: 100vw;
        min-height: 100vh;
        overflow: scroll;
        align-items: center;
        justify-content: center;
    }
    #modal .container-1 {
        flex-direction: column;
    }
    #modal .popUp {
        width: 80vw;
    }
    #modal .popUp .container-1 img {
        width: 90%;
    }
    #modal .popUp .container-1 {
        height: auto;
    }
    #modal .popUp .container-1 .chars:nth-of-type(1) span {
        font-size: 40px;
    }
    #modal .popUp .container-1 .statistics {
        width: 100%;
        margin-bottom: 100px;
        padding: 0;
        display: flex;
        flex-direction: column;
    }
}