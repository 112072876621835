#comprar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#comprar .col img {
    max-width: 100%;
    height: auto;
    max-height: 600px;
}

#comprar .line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 50px 0;
}

#comprar .line .col {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
    position: relative;
}

#comprar .line .col:nth-of-type(2) {
    gap: 20px;
    padding: 20px;
}

#comprar .line .col:nth-of-type(1) {
    display: flex;
    align-content: center;
    align-items: center;
}

#comprar .CardBtn {
    transition: 0.5s;
    border-radius: 4rem;
    border-style: none;
    background: var(--verde-claro);
    height: 50px;
    width: 10rem;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bolder;
    margin-top: 25px;
}

#comprar :is(h4, h1, h3) {
    max-width: 80%;
}

#comprar h4 {
    color: #ccc;
}

#comprar .CardBtn:hover {
    transition: 0.5s;
    transform: scale(1.05);
    box-shadow: 0px 0px 16px 2px var(--verde-claro);
    font-weight: bolder;
}

@media screen and (max-width: 968px) {
    #comprar .line {
        flex-direction: column-reverse;
    }
    #comprar .line .col {
        width: 100%;
    }
}