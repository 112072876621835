#hero {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

#hero img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-repeat: repeat;
    background-size: cover;
    z-index: 1;
    filter: brightness(.70);
}

#hero .line {
    z-index: 2;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    gap: 10px;
}

#hero :is(h1, h2) {
    color: white;
    text-align: left;
    font-weight: 700;
}

#hero h1 {
    font-size: 30px;
}

#hero h1:nth-of-type(2) {
    font-size: 50px;
}

#hero h2 {
    font-size: 24px;
    text-align: center;
}

#hero .ImgWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 968px) {
    .HeroContainer {
        height: 100vh;
        background: none;
    }
}