#FooterContainer {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    min-height: 20vh;
}

#FooterContainer p {
    font-weight: 500;
    font-size: 12px;
    color: #ccc;
}

#FooterContainer .FooterRow {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    height: auto;
}

#FooterContainer .FooterRow img {
    max-height: 30px;
}

#FooterContainer a {
    transition: .5s;
    list-style: none;
    text-align: center;
    text-decoration: none;
}