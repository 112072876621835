#PopUpBuy .input-div input::placeholder {
    color: transparent;
}

#PopUpBuy .input-div input:placeholder-shown~.input-label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}

#PopUpBuy .input-div .input-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: black;
}

#PopUpBuy .input-div input:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-color: var(--verde-escuro);
    border-image-slice: 1;
    color: black;
}

#PopUpBuy .input-div input:focus~.input-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: var(--verde-escuro);
    font-weight: 700;
}

#PopUpBuy .input-div input:required,
.input-div input:invalid {
    box-shadow: none;
}

#PopUpBuy .input-div {
    position: relative;
    padding: 15px 0 0;
    width: 100%;
}

#PopUpBuy .input-div input {
    width: 100%;
    border: 0;
    border-bottom: 2px solid black;
    outline: 0;
    font-size: 1.3rem;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    color: black;
}

#PopUpBuy .toggle {
    align-items: center;
    display: flex;
    font-weight: 700;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-size: 1.3rem;
    color: black;
    margin-top: 10px;
}

#PopUpBuy .toggle:hover a {
    color: var(--verde-escuro);
}

#PopUpBuy .toggle a {
    color: black;
}

#PopUpBuy .toggle:last-of-type {
    margin: 0;
}

#PopUpBuy .toggle__input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

#PopUpBuy .toggle__input:disabled+.toggle-track {
    cursor: not-allowed;
    opacity: 0.7;
}

#PopUpBuy .toggle-track {
    background: transparent;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    height: 30px;
    position: relative;
    width: 60px;
    border: 2px solid black;
    align-items: center;
}

#PopUpBuy .toggle-indicator {
    align-items: center;
    background: black;
    border-radius: 24px;
    bottom: 2px;
    display: flex;
    height: 23px;
    justify-content: center;
    left: 2px;
    outline: solid 2px transparent;
    position: absolute;
    transition: 0.5s;
    width: 23px;
}

#PopUpBuy .checkMark {
    fill: white;
    height: 20px;
    width: 20px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

#PopUpBuy .toggle__input:checked+.toggle-track .toggle-indicator {
    background: var(--verde-escuro);
    transform: translateX(29px);
}

#PopUpBuy .toggle__input:checked+.toggle-track .toggle-indicator .checkMark {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

@media screen and (-ms-high-contrast: active) {
    #PopUpBuy .toggle-track {
        border-radius: 0;
    }
}