#nft {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#nft .left {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    width: 50%;
    gap: 20px;
}

#nft .container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#nft .container img {
    width: 45%;
    height: auto;
    border-radius: 10px;
}

@media screen and (max-width: 960px) {
    #nft .container {
        flex-direction: column;
        gap: 30px;
    }
    #nft .left {
        width: 100%;
    }
    #nft .container img {
        width: 100%;
    }
}