#PopUpAlert {
    transition: .5s;
    position: fixed;
    right: 15px;
    top: 15px;
    width: auto;
    height: auto;
    z-index: 200;
    --size: 20rem
}

#PopUpAlert .container-1 {
    transition: .5s;
    position: absolute;
    border-radius: 5px;
    padding: 10px;
    min-width: var(--size);
    width: var(--size);
    right: calc(0px - var(--size) - 20px);
    animation: dirChange 1s linear 1s both alternate;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    height: auto;
    color: var(--secondary-colour);
}

#PopUpAlert .container-1.active {
    transition: .5s;
    right: 0;
}

#PopUpAlert .container-1.inactive {
    transition: .5s;
    right: calc(0px - var(--size) - 20px);
}

#PopUpAlert .container-1 h3 {
    font-size: 20px;
}

#PopUpAlert .container-1 h4 {
    font-size: 15px;
}