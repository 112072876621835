#PopUpBuy {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
}

#PopUpBuy :is(h1, p) {
    color: white;
    text-align: center;
    max-width: 70%;
}

#PopUpBuy form :is(h1, p) {
    color: black;
    text-align: center;
    max-width: unset;
}

#PopUpBuy .icon {
    transition: .5s;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: auto;
    cursor: pointer;
    z-index: 999;
    transform: scale(1);
}

#PopUpBuy .btnsDiv button {
    transition: .5s;
    height: 60px;
    width: 50%;
    border: 2px solid var(--verde-escuro);
    border-radius: 20px;
    background-color: white;
    font-size: 18px;
    cursor: pointer;
    position: relative;
}

#PopUpBuy .btnsDiv button:nth-of-type(1) {
    border-radius: 20px 0 0 20px;
    border-right: none;
    background-color: var(--verde-escuro);
    color: white;
}

#PopUpBuy .btnsDiv button:nth-of-type(2) {
    border-radius: 0 20px 20px 0;
    border-left: none;
}

#PopUpBuy .icon.off {
    transition: .5s;
    transform: scale(0);
}

#PopUpBuy .icon:hover {
    transition: .5s;
    color: var(--verde-escuro);
}

#PopUpBuy form button {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    border: 0;
    background-color: var(--verde-escuro);
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: ease-in .3s;
}

#PopUpBuy form button:hover {
    transition: ease-in .3s;
    background-color: #078b54;
}

#PopUpBuy .forms {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
}

#PopUpBuy .div {
    transition: .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: scale(0);
    position: absolute;
    width: 100%;
    height: 100%;
    gap: 20px;
}

#PopUpBuy .div .form {
    position: absolute;
    transition: .5s;
    transform: scale(0);
    overflow: hidden;
    overflow-y: auto;
    height: 0;
    width: 0;
}

#PopUpBuy .div .form.active {
    transition: .5s;
    height: auto;
    width: auto;
    max-width: 80%;
    transform: scale(1);
}

#PopUpBuy .div.show {
    transition: .7s;
    transform: scale(1);
}

#PopUpBuy .btnsDiv.active {
    display: flex;
    transition: .5s;
    transform: scale(1);
    width: 50%;
}

#PopUpBuy .titles.active {
    transition: .7s;
    transform: scale(1);
    text-align: center;
}

#PopUpBuy .titles {
    transition: .7s;
    transform: scale(0);
}

#PopUpBuy .btnsDiv {
    transform: .5s;
    transform: scale(0);
}

#PopUpBuy form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
    background-color: white;
    max-height: 90%;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
    border-radius: 20px;
    overflow: hidden;
    overflow-y: auto;
}

#PopUpBuy form h1 {
    margin-top: 40px;
}

#PopUpBuy :is(.esquerda, .direita) {
    transition: .8s;
    width: 50%;
    height: 100%;
    position: relative;
}

#PopUpBuy :is(.esquerda img, .direita img) {
    width: 100%;
    height: 100%;
    z-index: 998;
    filter: brightness(.6);
    position: absolute;
    top: 0;
    left: 0;
}

#PopUpBuy .esquerda {
    transform: translate(100%);
}

#PopUpBuy .direita {
    transform: translate(-100%);
}

#PopUpBuy .esquerda.open {
    transform: translate(-100%);
}

#PopUpBuy .direita.open {
    transform: translate(100%);
}

@media screen and (max-width: 968px) {
    #PopUpBuy .direita {
        display: none;
    }
    #PopUpBuy .esquerda {
        width: 100%;
    }
    #PopUpBuy .esquerda.open {
        transition: .5s;
        transform: translate(0);
    }
    #PopUpBuy form {
        width: 80%;
        justify-content: flex-start;
    }
    #PopUpBuy .input-div .input-label {
        font-size: 5px;
    }
    #PopUpBuy .icon {
        top: 10px;
        right: 10px;
    }
}