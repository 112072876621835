#passbook {
    background-color: var(--bg);
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    flex-direction: column;
}

#passbook .navBar {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#passbook .navBar .container-2 button {
    height: 50px;
    width: 100px;
    outline: none;
    border-radius: 0;
    text-decoration: none;
    border: 3px solid var(--verde-escuro);
    border-left: 0;
    border-right: 0;
    color: white;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

#passbook .navBar .container-2 svg {
    transition: .5s;
    cursor: pointer;
    height: 30px;
    width: 30px;
}

#passbook .navBar .container-2 svg:hover {
    transition: .5s;
    color: var(--roxo);
}

#passbook .navBar .container-2 {
    display: flex;
    align-items: center;
}

#passbook .navBar .container-2 .selected {
    background-color: var(--roxo);
}

#passbook .navBar .container-2 button:nth-of-type(7) {
    border-radius: 0 10px 10px 0;
    border-right: 3px solid var(--verde-escuro);
}

#passbook .navBar .container-2 button:nth-of-type(1) {
    border-radius: 10px 0 0 10px;
    border-left: 3px solid var(--verde-escuro);
}

#passbook .element {
    width: 100%;
    height: 100%;
}

#passbook .PassbookCards {
    display: grid;
    grid-template-columns: repeat(5, calc(100% /5));
    justify-content: center;
    align-items: center;
    height: auto;
    column-gap: 20px;
    row-gap: 40px;
    width: 100vw;
    padding: 2% 15%;
}

@media screen and (max-width: 968px) {
    #passbook .PassbookCards {
        grid-template-columns: repeat(2, calc(100% /2));
        width: 100vw;
        padding: 7% 0;
        padding-left: 5%;
        column-gap: 0;
    }
    #passbook .navBar .container-2 button {
        height: 50px;
        width: 45px;
        overflow: hidden;
    }
}