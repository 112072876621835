#faq {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#faq .container {
    overflow: unset;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#faq .pergunta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    row-gap: 30px;
}

#faq .pergunta .faq {
    padding: 1rem;
    background-color: transparent;
    height: min-content;
    width: 49%;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid var(--verde-claro);
    box-shadow: 0 0 12px var(--verde-escuro);
    color: #fff;
    transition: all 0.5s ease;
}

#faq .faq.open {
    transition: all 0.5s ease;
    height: max-content;
}

#faq .pergunta .faq div {
    position: relative;
    padding: 0 1rem;
    font-size: 1.1rem;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

#faq svg {
    color: var(--verde-claro);
}

#faq .pergunta .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.5s ease;
    font-size: 16px;
}

#faq .faq-answer {
    color: var(--secondary-colour);
    margin-left: 1rem;
    font-size: 0.9rem;
}

#faq .pergunta .faq.open div {
    margin-bottom: 1rem;
}

#faq .pergunta .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
    transition: all 0.5s ease;
}

@media screen and (max-width: 968px) {
    #faq .pergunta {
        flex-direction: column;
    }
    #faq .pergunta .faq {
        width: 100%;
    }
}